<template>
  <footer class="w-full" :class="invert ? 'fixed bottom-0 c-white' : 'b-t b-grey-80'">
    <div
      v-if="invert"
      class="absolute inset-x-0 bottom-0"
      style="background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.6) 75%); height: 15rem"
    />
    <ul class="relative flex gap-6 p-6">
      <li
        v-for="({ icon, title, to }, i) in data?.links?.legal"
        :key="i"
        :class="{ 'flex items-center relative': icon }"
      >
        <base-link :to :class="{ 'pr-10 z-1': icon }">
          {{ title }}
        </base-link>
        <vf-icon v-if="icon" :name="icon" size="xl" class="absolute right-0" />
      </li>
    </ul>
  </footer>
</template>

<script lang="ts" setup>
defineProps<{
  invert?: boolean
}>()

const { data } = await useApi().internal.getFooter()
</script>
